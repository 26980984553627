import React, { Component } from "react";
import { FaFacebook, FaTwitter, FaInstagram, FaTiktok } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import "./Redes.css";

class Redes extends Component {
  modal() {
    const modal = document.getElementById("modal");
    modal.classList.toggle("is_open");
  }

  render() {
    return (
      <div className="redes">
        <div className="social_profiles">
          <div className="SocialShare social_profile_item">
            <a
              href="https://www.facebook.com/PixelDesignAr?mibextid=ZbWKwL"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook />
            </a>
          </div>
          <div className="SocialShare social_profile_item">
            <a
              href="https://x.com/pixeldesignar?t=XB0PI9ON-BNexy4dZhUMfQ&s=08"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaXTwitter />
            </a>
          </div>
          <div className="SocialShare social_profile_item">
            <a
              href="https://www.instagram.com/pixeldesignar?igsh=eDRncm5vN2V4bjRw"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </a>
          </div>
          <div className="SocialShare social_profile_item">
            <a
              href="https://www.tiktok.com/@pixeldesignar?_t=8ir2np11g1t&_r=1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTiktok />
            </a>
          </div>
        </div>
        <p>
          <span>
            Copyright 2024 por Pixel Design Ar, Inc. Todos los derechos
            reservados.
          </span>
        </p>
      </div>
    );
  }
}

export default Redes;
