import React from "react";
import ReactDOM from "react-dom";
import Preloader from "./components/Preloader/Preloader";
import Timer from "./components/Countdown/Timer";
import Optin from "./components/Optin/Optin";
import Redes from "./components/Redes/Redes";
import ButtonLeft from "./assets/img/button-left.webp";
import ButtonRight from "./assets/img/button-right.webp";
import TopLeft from "./assets/img/top-left.webp";
import TopRight from "./assets/img/top-right.webp";
import Logo from "./assets/img/logo.png";

import "./styles.css";

function App() {
  return (
    <div className="App">
      <section className="p-relative">
        <div className="container content-header">
          <div className="logo-body">
            <img className="logo" src={Logo} alt="logo" />
          </div>
        </div>

        <div className="overlay">
          <img className="animate TopLeft" src={TopLeft} alt="" />
          <img className="animate TopRight" src={TopRight} alt="" />
          <img className="animate ButtonLeft" src={ButtonLeft} alt="" />
          <img className="animate ButtonRight" src={ButtonRight} alt="" />
        </div>

        <div className="container content-body">
          <h1>
            Pixel Desing
            <br />
            AR
          </h1>
          <Timer />
          <Optin />
          <Redes />
          <Preloader />
        </div>
      </section>
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
